import React from "react"
import type { HeadFC, PageProps } from "gatsby"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Attraction } from "@calpa/ui";
import { Grid, Typography } from "@mui/material";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(
  () => ({
    legend: {
      top: '50%',
      background: `transparent`,
      opacity: 1,
      fontSize: 32,
      fontWeight: 400,
      padding: 0,
      transition: `all .5s ease-in-out`,
      position: `absolute`,
      bottom: `40px`,
      left: `50%`,
      marginLeft: `-45%`,
      width: `90%`,
      borderRadius: `10px`,
      color: `#fff`,
      textAlign: `center`,
    },
    carousel: {
      '.control-dots': {
        display: `flex`,
        margin: `0 !important`,
      }
    },
    indicator: {
      cursor: `pointer`,
      display: `inline-flex`,
      backgroundColor: `rgba(0,0,0,0.3)`,
      flexGrow: 1,
      padding: `30px`
    },
    selectedIndicator: {
      borderBottom: `10px solid red`
    }
  }));

const IndexPage = (props: PageProps<Queries.IndexPageQuery>) => {
  const { data } = props;

  const { classes, cx } = useStyles();

  return (
    <Grid container maxWidth="lg" sx={{
      margin: `0 auto`
    }}>
      <Grid
        item
        xs={12}
        sx={{
          userSelect: "none",
        }}
        className={classes.carousel}
      >
        <Carousel
          autoPlay
          infiniteLoop
          dynamicHeight={false}
          interval={5000}
          showStatus={false}
          showThumbs={false}
          renderIndicator={(
            clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
            isSelected: boolean,
            index: number,
            // label: string
          ) => (
            <Grid
              onClick={clickHandler}
              className={cx(classes.indicator, isSelected && classes.selectedIndicator)}
            >
              <Typography
                sx={{
                  color: `white`,
                  width: `100%`,
                  textAlign: `left`
                }}
                variant="h3">
                {data.allContentfulAttraction.edges[index].node.name}
              </Typography>
            </Grid>
          )}
        >
          {data.allContentfulAttraction.edges.map(({ node }) => {
            const image = node.images && node.images[0]?.carouselImage?.localFile?.childImageSharp?.gatsbyImageData;
            if (image) {
              return (
                <div id={`carousel-${node.id}`} key={node.id}>
                  <GatsbyImage
                    image={image}
                    alt={node.name}
                    title={node.name}
                  />
                  <Typography className={cx(classes.legend)}>{node.name}</Typography>
                </div>
              )
            }

            return <></>;
          }
          )}
        </Carousel>
      </Grid>


      {data.allContentfulAttraction.edges.map(({ node: attraction }) => (
        <Attraction
          title={attraction.name || ''}
          description={attraction.description?.description || ''}
          location={attraction.name || ''}
          key={attraction.name}
          Image={() => {
            const image = attraction.images && attraction.images[0]?.carouselImage?.localFile?.childImageSharp?.smallImageData;
            if (image) {
              return (
                <GatsbyImage
                  image={image}
                  alt={attraction.name}
                  key={attraction.name}
                />
              )
            }
          }
          }
        />
      ))}
    </Grid>
  )
}

export default IndexPage

const SEO = ({ children }) => (
  <>
    {children}
  </>
)

export const Head: HeadFC = () =>
  <SEO>
    <title>Tokyo Tansaku</title>
  </SEO>

export const query = graphql`
query IndexPage {
  allContentfulAttraction {
    edges {
      node {
        id
        name
        description {
          description
        }
        images(limit: 1) {
          carouselImage: image {
            localFile {
              name
              childImageSharp {
                gatsbyImageData(width: 1920, height: 640)
                smallImageData: gatsbyImageData(width: 600, height: 300)
              }
            }
          }
        }
      }
    }
  }
}
`